import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Seo from "../components/SEO";
import { BlogStub } from "../components/BlogStub";
import SplashTitle from "../components/SplashTitle";
import { getImage } from "gatsby-plugin-image";
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from "@heroicons/react/solid";

import { useIntl, Link, FormattedMessage } from "gatsby-plugin-react-intl";
import { getAllResolvedVersionsForLanguage } from "../utilities/Node";

export default function BlogIndex({ pageContext, data }) {
  const { numPages, currentPage } = pageContext;

  const intl = useIntl();

  // Pagination variables
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? `/blog` : `/blog/${currentPage - 1}`;
  const nextPage = `/blog/${currentPage + 1}`;

  // Load all posts of the graphql query into an array and normalize to default Language
  const posts = getAllResolvedVersionsForLanguage(data, intl);

  // Create an array where all pagination links are stored
  // Link of first page is always just /blog
  const paginationLinks = [...Array(numPages)].map((_, i) => {
    const link = i === 0 ? { to: `/blog`, text: "1" } : { to: `/blog/${i + 1}`, text: `${i + 1}` };
    return link;
  });

  const goToPosts = (link) => {
    return link + "#blog-posts";
  };

  function Pagination() {
    return (
      <nav className="border-t border-gray-200 px-4 flex items-center justify-between sm:px-8 pb-16">
        <div className="-mt-px w-0 flex-1 flex">
          <Link
            to={isFirst ? goToPosts("/blog") : goToPosts(prevPage)}
            className="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
          >
            <ArrowNarrowLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
            <FormattedMessage id="blog.pagination-previous" defaultMessage="Previous" />
          </Link>
        </div>

        <div className="hidden md:-mt-px md:flex">
          {paginationLinks.map((link, i) => {
            return (
              <Link
                to={goToPosts(link.to)}
                key={i}
                className="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium"
                activeClassName="border-uc-500"
              >
                {link.text}
              </Link>
            );
          })}
        </div>
        <div className="-mt-px w-0 flex-1 flex justify-end">
          <Link
            to={isLast ? goToPosts(paginationLinks[paginationLinks.length - 1].to) : goToPosts(nextPage)}
            className="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
          >
            <FormattedMessage id="blog.pagination-next" defaultMessage="Next" />
            <ArrowNarrowRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
          </Link>
        </div>
      </nav>
    );
  }

  return (
    <Layout intl={intl} title="Blog">
      <Seo title="Blog" />
      <SplashTitle
        image={getImage(data.splashImage)}
        subheadline={intl.formatMessage(
          { id: "blog-index.subheadline", defaultMessage: "Our <mark>Blog</mark>" },
          { mark: (text) => <mark>{text}</mark> }
        )}
      ></SplashTitle>
      <main className="max-w-screen-2xl mx-auto">
        <div id="blog-posts" className="relative bg-gray-50 pt-8 pb-8 px-4 sm:px-6 lg:pt-8 lg:px-8">
          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            {posts.map((node) => {
              return <BlogStub key={node.id} intl={intl} post={node} />;
            })}
          </div>
        </div>
        <Pagination />
      </main>
    </Layout>
  );
}

export const pageQuery = graphql`
  query BlogPageQuery($slugs: [String!]!) {
    allMdx(filter: { fields: { slug: { in: $slugs } } }, sort: { order: DESC, fields: frontmatter___date }) {
      edges {
        node {
          id
          fields {
            slug
            lang
          }
          frontmatter {
            author
            date
            category
            featureImageAlt
            featureImage {
              childImageSharp {
                gatsbyImageData(width: 250, aspectRatio: 1.5)
              }
            }
            title
          }
          excerpt(pruneLength: 280)
          timeToRead
        }
      }
    }
    splashImage: file(relativePath: { eq: "suburb.jpg" }, sourceInstanceName: { eq: "splash" }) {
      ...SplashImage
    }
  }
`;
